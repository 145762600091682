export const STEP_ERROR = 'error';
export const STEP_PREPARE = 'prepare';
export const STEP_EXPIRED = 'expired';
export const STEP_QR_CODE = 'qr-code';
export const STEP_PRESENTATION = 'presentation';
export const STEP_ALREADY_SHARED = 'already-shared';
export const STEP_CONFIRM_OWNER_DATA = 'confirm-owner-data';
export const STEP_CONGRATULATIONS = 'congratulations';
export const STEP_CONGRATULATIONS_RETURN_TO_DEVICE = 'congratulations-return-to-device';
export const STEP_SHARING_VERIFICATION = 'sharing-verification';
export const STEP_VERIFICATION_FINISHED = 'verification-finished';
export const STEP_ORDER_NOT_RECOGNIZED = 'order-not-recognized';
export const STEP_REGISTER_NOT_RECOGNIZED = 'register-not-recognized';
export const STEP_HELP_PERMISSION = 'help-permission';
export const STEP_HELP_PHOTO_QUALITY = 'help-photo-quality';
export const STEP_VALIDATION_FRICTIONLESS = 'validation-frictionless';
export const STEP_CONGRATULATIONS_FRICTIONLESS = 'congratulations-frictionless';
export const STEP_SUCCESS_CONNECTION_BELVO = 'success-connection-belvo';
export const STEP_CARD_VALIDATION_BELVO = 'card-validation-belvo';
export const STEP_AUTH = 'auth';
export const STEP_INCORRECT_LINK = 'incorrect-link';
export const STEP_FINISH_BELVO = 'finish-belvo';
