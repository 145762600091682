import {
  STEP_CARD_VALIDATION_BELVO,
  STEP_CONGRATULATIONS_FRICTIONLESS,
  STEP_ERROR, STEP_EXPIRED, STEP_PREPARE,
  STEP_PRESENTATION,
  STEP_VERIFICATION_FINISHED,
} from "../shared-steps/steps";

export const TRANSACTION_STEP__INIT = 'init';
export const TRANSACTION_STEP__EXPIRED = 'expired';
export const TRANSACTION_STEP__OPEN_FINANCE = 'open-finance';
export const TRANSACTION_STEP__CONGRATULATIONS = 'congratulations';

export const TRANSACTION_NEXT_STEP__STOP = 'stop';
export const TRANSACTION_NEXT_STEP__ERROR = 'error';
export const TRANSACTION_NEXT_STEP__CONTINUE_BIOMETRIC = 'continue-biometric';
export const TRANSACTION_NEXT_STEP__CONTINUE_OPEN_FINANCE = 'continue-open-finance';

export const CURRENT_STEP_TO_STEP = {
  [TRANSACTION_STEP__INIT]: STEP_PRESENTATION,
  [TRANSACTION_STEP__EXPIRED]: STEP_EXPIRED,
  [TRANSACTION_STEP__OPEN_FINANCE]: STEP_CARD_VALIDATION_BELVO,
  [TRANSACTION_STEP__CONGRATULATIONS]: STEP_CONGRATULATIONS_FRICTIONLESS,
};

export const NEXT_STEP_TO_STEP = {
  [TRANSACTION_NEXT_STEP__STOP]: STEP_CONGRATULATIONS_FRICTIONLESS,
  [TRANSACTION_NEXT_STEP__CONTINUE_OPEN_FINANCE]: STEP_CARD_VALIDATION_BELVO,
  [TRANSACTION_NEXT_STEP__CONTINUE_BIOMETRIC]: STEP_PREPARE,
  [TRANSACTION_NEXT_STEP__ERROR]: STEP_ERROR,
};

export const NEXT_STEP_TO_STEP_BELVO = {
  [TRANSACTION_NEXT_STEP__STOP]: STEP_VERIFICATION_FINISHED,
  [TRANSACTION_NEXT_STEP__CONTINUE_OPEN_FINANCE]: STEP_CARD_VALIDATION_BELVO,
  [TRANSACTION_NEXT_STEP__CONTINUE_BIOMETRIC]: STEP_PREPARE,
}