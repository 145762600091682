import { fetchIntercept, removeParamUrl } from "@frontend/shared-utility";

const handleResponse = async (response) => {
  if (!response.ok) {
    const responseData = await response.text();

    let error;

    try {
      const {
        error: { description, code },
      } = JSON.parse(responseData);

      error = new Error(description);
      error.code = code;
    } catch (e) {
      error = new Error('Response not ok');
    }

    throw error;
  }

  const responseObj = await response.json();

  return responseObj;
}

const getCurrentUrl = () => {
  const url = window.location.href;
  const currentUrl = url.includes('localhost') ? process.env.NX_PUBLIC_HOST : url;

  return removeParamUrl(currentUrl, 'code');
}

const setCookieCodeVerifier = (codeVerifier) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() + 5);
  const expires = date.toUTCString();

  document.cookie = `codeVerifier=${codeVerifier}; expires=${expires}; path=/`;
}

const getIdentityURLWithCodeChallenge = async () => {
  const responseBody = {
    scope: process.env.NX_PUBLIC_IDENTITY_SCOPE,
    clientID: process.env.NX_PUBLIC_IDENTITY_CLIENT_ID,
    redirectURL: getCurrentUrl(),
  };

  const response = await fetch(`${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/v1/login/start`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(responseBody)
  });

  const responseObj = await handleResponse(response);

  setCookieCodeVerifier(responseObj.codeVerifier);

  return responseObj.url;
}

const getIdentityURLWithCodeChallengeWithIntercept = async () => {
  const responseBody = {
    scope: process.env.NX_PUBLIC_IDENTITY_SCOPE,
    clientID: process.env.NX_PUBLIC_IDENTITY_CLIENT_ID,
    redirectURL: getCurrentUrl(),
  };

  const response = await fetchIntercept(`${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/v1/login/start`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(responseBody)
  }, false, true);

  const responseObj = await handleResponse(response);

  setCookieCodeVerifier(responseObj.codeVerifier);

  return responseObj.url;
}

const getAccessToken = async (authorizationCode, codeVerifier) => {
  const responseBody = {
    clientID: process.env.NX_PUBLIC_IDENTITY_CLIENT_ID,
    redirectURL: getCurrentUrl(),
    code: authorizationCode,
    codeVerifier
  }

  const response = await fetch(`${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/v1/login/finish`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(responseBody)
  });

  const responseObj = await handleResponse(response);

  return responseObj.token;
}

const getAccessTokenWithIntercept = async (authorizationCode, codeVerifier) => {
  const responseBody = {
    clientID: process.env.NX_PUBLIC_IDENTITY_CLIENT_ID,
    redirectURL: getCurrentUrl(),
    code: authorizationCode,
    codeVerifier
  }

  const response = await fetchIntercept(`${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/v1/login/finish`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(responseBody)
  }, false, true);

  const responseObj = await handleResponse(response);

  return responseObj.token;
}

const logout = async (accessToken) => {
  const response = await fetch(`${process.env.NX_PUBLIC_IDPAY_ENDPOINT}/api/v1/logout`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${accessToken}`
    },
  });

  return await handleResponse(response);
}

export {
  getIdentityURLWithCodeChallenge,
  getAccessToken,
  logout,
  getIdentityURLWithCodeChallengeWithIntercept,
  getAccessTokenWithIntercept,
}