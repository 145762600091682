export const SCOPE_TRACK__B2C = 'B2C';

export const EVENT_TRACK__SHARE = 'share';
export const EVENT_TRACK__CLICK = 'click';
export const EVENT_TRACK__START_SDK = 'startSDK';
export const EVENT_TRACK__ERROR_SDK = 'errorSDK';
export const EVENT_TRACK__OPEN_MODAL = 'openModal';
export const EVENT_TRACK__OPEN_POPUP = 'openPopUp';
export const EVENT_TRACK__START_FLOW = 'startFlow';
export const EVENT_TRACK__FINALLY_FLOW = 'finallyFlow';
export const EVENT_TRACK__SHOW_FLOW = 'showFlow';
export const EVENT_TRACK__ERROR_FLOW = 'errorFlow';
export const EVENT_TRACK__REDIRECT_TO_LINK = 'redirectToLink';
export const EVENT_TRACK__HANDLE_POLLING_REDIRECT = 'handlePollingRedirect';
export const EVENT_TRACK__PAGE_VIEWED = 'pageViewed';
export const EVENT_TRACK__SUCCESS_SDK = 'successSDK';
export const EVENT_TRACK__SUCCESS_VALIDATE_BIO = 'successValidateBio';
export const EVENT_TRACK__PREPARE_INIT_SDK = 'prepareInitSDK';
export const EVENT_TRACK__PREPARE_FINALLY_SDK = 'prepareFinallySDK';
export const EVENT_TRACK__PRE_LOADING_INIT_SDK = 'preLoadingInitSDK';
export const EVENT_TRACK__PRE_LOADING_FINALLY_SDK = 'preLoadingFinallySDK';
export const EVENT_TRACK__LOADING_INIT_SDK = 'loadingInitSDK';
export const EVENT_TRACK__LOADING_FINALLY_SDK = 'loadingFinallySDK';
export const EVENT_TRACK__CAPTURE_BUTTON_DISPLAYED_SDK = 'captureButtonDisplayedSDK';
export const EVENT_TRACK__NOT_RECOGNIZE = 'notRecognize';
export const EVENT_TRACK__CHANGE_LANGUAGE = 'changeLanguage';
export const EVENT_TRACK__ASSUME_OWNERSHIP = 'assumeOwnership';
export const EVENT_TRACK__CONFIRM_NOT_OWNER = 'confirmNotOwner';
export const EVENT_TRACK__INTEGRATION_ERROR = 'integrationError';
export const EVENT_TRACK__API_ERROR = 'apiError';
export const EVENT_TRACK__REQUEST_PERMISSION = 'requestPermission';
export const EVENT_TRACK__RESULT_REQUEST_PERMISSION = 'resultRequestPermission';
export const EVENT_TRACK__EXPIRED_TIMER = 'expiredTimer';
export const EVENT_TRACK__STOP_SESSION_CAMERA = 'stopSessionCamera';
export const EVENT_TRACK__ERROR_OPEN_SDK = 'errorOpenSDK';
export const EVENT_TRACK__START_CHECK_PERMISSIONS_POPUP = 'startCheckPermissionsPopUp';
export const EVENT_TRACK__NOT_OPEN_PERMISSIONS_POPUP = 'notOpenPermissionsPopUp';
export const EVENT_TRACK__ERROR_PREPARE_SDK = 'errorPrepareSDK';
export const EVENT_TRACK__BUILD_INIT_SDK = 'buildInitSDK';
export const EVENT_TRACK__BUILD_FINALLY_SDK = 'buildFinallySDK';
export const EVENT_TRACK__HELP_CHAT = 'helpChat';
export const EVENT_TRACK__HELP_CHAT_CATEGORY = 'helpChatCategory';

export const EVENT_TRACK__EXIT_BELVO = 'exitBelvo';
export const EVENT_TRACK__SUCCESS_BELVO = 'successBelvo';
export const EVENT_TRACK__ERROR_BELVO = 'errorBelvo';
export const EVENT_TRACK__REDIRECT_CALLBACK = 'redirectCallback';
export const EVENT_TRACK__POST_MESSAGE_CALLBACK = 'postMessageCallback';
export const EVENT_TRACK__RECEIVE_CALLBACK = 'receiveCallback';
export const EVENT_TRACK__OPEN_URL_BELVO = 'openUrlBelvo';
export const EVENT_TRACK__RECEIVE_POST_MESSAGE_CALLBACK = 'receivePostMessageCallback';

export const EVENT_TRACK__REQUEST_SESSION_ID = 'requestSessionID';
export const EVENT_TRACK__REQUEST_COLLECTION_ID = 'requestCollectionID';
export const EVENT_TRACK__ERROR_REQUEST_FRICTIONLESS_STATUS = 'errorRequestFrictionlessStatus';
export const EVENT_TRACK__RESULT_SESSION_ID = 'resultSessionID';
export const EVENT_TRACK__RESULT_COLLECTION_ID = 'resultCollectionID';
export const EVENT_TRACK__TIMEOUT_COLLECTION_ID = 'timeoutCollectionID';
export const EVENT_TRACK__TIMEOUT_SESSION_ID = 'timeoutSessionID';
export const EVENT_TRACK__REQUEST_FRICTIONLESS_STATUS = 'requestFrictionlessStatus';
export const EVENT_TRACK__FRICTIONLESS_STATUS = 'frictionlessStatus';
export const EVENT_TRACK__FINALLY_TIMER_FRICTIONLESS = 'finallyTimerFrictionless';

export const CLICK_TRACK__BACK = 'back';
export const CLICK_TRACK__SHARE = 'share';
export const CLICK_TRACK__CONFIRM = 'confirm';
export const CLICK_TRACK__COPY_LINK = 'copyLink';
export const CLICK_TRACK__TRY_AGAIN = 'tryAgain';
export const CLICK_TRACK__OPEN_CLOSE_TIP = 'openTip';
export const CLICK_TRACK__LOAD_TIP_PAGE = 'loadTipPage';
export const CLICK_TRACK__HELP_TO_SELFIE = 'helpToSelfie';
export const CLICK_TRACK__CAPTURE_SELFIE = 'captureSelfie';
export const CLICK_TRACK__ALREADY_SHARED = 'alreadyShared';
export const CLICK_TRACK__PRIVACY_POLICY = 'privacyPolicy';
export const CLICK_TRACK__CAPTURE_WITH_WEBCAM = 'captureWithWebcam';
export const CLICK_TRACK__CAPTURE_WITH_MOBILE = 'captureWithMobile';
export const CLICK_TRACK__NO_WEBCAM = 'noWebcam';
export const CLICK_TRACK__ASSUME_OWNER_SHARE_VERIFICATION ='assumeOwnerShareVerification';
export const CLICK_TRACK__CONTINUE_CAPTURE ='continueCapture';
export const CLICK_TRACK__CONTINUE_VERIFICATION ='continueVerification';
export const CLICK_TRACK__HELP_PORTAL = 'helpPortal';
export const CLICK_TRACK__OPEN_BELVO = 'openBelvo';

export const MODAL_TRACK__ERROR_BELVO = 'errorBelvo';
export const CLICK_TRACK__TRY_AGAIN_ERROR_BELVO_MODAL = 'tryAgainErrorBelvoModal';

export const MODAL_TRACK__NOT_NOW = 'notNow';
export const CLICK_TRACK__OPEN_NOT_NOW_MODAL = 'openNotNowModal';
export const CLICK_TRACK__RETURN_VALIDATION_NOT_NOW_MODAL =
'returnValidationNotNowModal';
export const CLICK_TRACK__CONFIRM_SKIP_NOT_NOW_MODAL =
'confirmSkipNotNowModal';

export const MODAL_TRACK__NOT_OWNER = 'notOwner';
export const CLICK_TRACK__OPEN_NOT_OWNER_MODAL = 'openNotOwnerModal';
export const CLICK_TRACK__ASSUME_OWNER_NOT_OWNER_MODAL =
'assumeOwnerNotOwnerModal';
export const CLICK_TRACK__CONFIRM_AND_CLOSE_NOT_OWNER_MODAL =
'confirmAndCloseNotOwnerModal';

export const MODAL_TRACK__EXPLAIN_CPF = 'explainCPF';
export const CLICK_TRACK__OPEN_EXPLAIN_CPF_MODAL = 'openExplainCPFModal';
export const CLICK_TRACK__CLOSE_EXPLAIN_CPF_MODAL = 'closeExplainCPFModal';

export const MODAL_TRACK__EXPLAIN_LINK = 'explainLink';
export const CLICK_TRACK__OPEN_EXPLAIN_LINK_MODAL = 'openExplainLinkModal';
export const CLICK_TRACK__CLOSE_EXPLAIN_LINK_MODAL = 'closeExplainLinkModal';

export const MODAL_TRACK__EXPLAIN_SELFIE = 'explainSelfie';
export const CLICK_TRACK__OPEN_EXPLAIN_SELFIE_MODAL = 'openExplainSelfieModal';
export const CLICK_TRACK__CLOSE_EXPLAIN_SELFIE_MODAL =
'closeExplainSelfieModal';

export const MODAL_TRACK__NOT_RECOGNIZE = 'notRecognize';
export const CLICK_TRACK__OPEN_NOT_RECOGNIZE_MODAL = 'openNotRecognizeModal';
export const CLICK_TRACK__CLOSE_NOT_RECOGNIZE_MODAL = 'closeNotRecognizeModal';
export const CLICK_TRACK__CONFIRM_NOT_RECOGNIZE_MODAL =
'confirmNotRecognizeModal';

export const POP_UP_TRACK__COPIED_LINK = 'copiedLink';
export const CLICK_TRACK__CLOSE_COPIED_LINK_POP_UP = 'closeCopiedLinkPopUp';

export const POP_UP_TRACK__ERROR = 'error';
export const CLICK_TRACK__CLOSE_ERROR_POP_UP = 'closeErrorPopUp';

export const POP_UP_TRACK__REQUIRE_PERMISSION = 'requirePermission';
export const CLICK_TRACK__CONTINUE_REQUIRE_PERMISSION_POP_UP = 'continueRequirePermissionPopUp';

export const POP_UP_TRACK__MOBILE_SHARE = 'mobileShare';

export const COMPONENT_PRIVACY_POLICY_LINK = 'privacyPolityLink';

export const COMPONENT_HELP_CHAT = 'helpChat';
