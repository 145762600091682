export const transformTreeToObjectList = (obj = {}, parentKey = '', maxLenghtKey = 24, wordsToRemove = []) => {
  let newObj = {};

  if (typeof obj !== 'object' || Array.isArray(obj)) return newObj;

  Object.keys(obj).forEach(key => {
    let newKey = `${parentKey}_${key}`;

    wordsToRemove.forEach(word => {
      newKey = newKey.replaceAll(word, '');
    });

    newKey = newKey.substring(0, maxLenghtKey);

    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      newObj = {
        ...newObj,
        ...transformTreeToObjectList(obj[key], newKey, maxLenghtKey)
      };
    } else {
      newObj[newKey] = obj[key];
    }
  });
  return newObj;
}