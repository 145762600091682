import { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { createContext } from 'use-context-selector';
import { getAccessToken, getAccessTokenWithIntercept, getIdentityURLWithCodeChallenge, getIdentityURLWithCodeChallengeWithIntercept } from "../../integrations/auth-pkce";
import { getHashParams, getByCookie } from "@frontend/shared-utility";
import {
  getAccessTokenFromStorage,
  setAccessTokenInStorage,
} from "../../defaults/auth-control";
import { useFlags } from "launchdarkly-react-client-sdk";

export const AuthPkceContext = createContext({});

const AuthPkceProvider = ({ children }) => {

  const [accessToken, setAccessToken] = useState(null);
  const { enablePkceWithIntercept } = useFlags();

  useEffect(() => {
    (async () => {
      let accessToken = getAccessTokenFromStorage();
      const authorizationCode = getHashParams(window.location.href).code;

      if (!accessToken && !authorizationCode) {
        window.location.href = enablePkceWithIntercept
          ? await getIdentityURLWithCodeChallengeWithIntercept()
          : await getIdentityURLWithCodeChallenge();
        return;
      }

      if (!accessToken) {
        accessToken = enablePkceWithIntercept 
        ? await getAccessTokenWithIntercept(authorizationCode, getByCookie('codeVerifier'))
        : await getAccessToken(authorizationCode, getByCookie('codeVerifier'))
      }

      setAccessToken(accessToken);
      setAccessTokenInStorage(accessToken);

    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(() => ({
    accessToken,
    setAccessToken,
  }), [accessToken]);

  return (
    <AuthPkceContext.Provider value={value}>
      {children}
    </AuthPkceContext.Provider>
  )
}

AuthPkceProvider.propTypes = {
  children: PropTypes.element
};

export default AuthPkceProvider;
